import React from "react";
import SingleThemeAside from '../components/single_theme_aside'

import PubSub from "pubsub-js";
import MyNavLink from "../components/my-nav-link";
import Layout from "../components/layout"
import "../css/theme_inroduction.css"
import DemoWindow from "../components/demo_window"

export default class ThemeIntroduction extends React.Component{
    state={
        showImage:false
    }
    componentWillMount(){
        // window.scrollTo(0,0)
    }
    componentDidMount() {
        PubSub.subscribe('closedemo',(msg,data)=>{
            this.closeDemo()
        })
    }

    showDemo=(e)=>{
        console.log('e:',e)
        // PubSub.publish('showdemo',e)
        this.setState({
            showdemo:true
        })
    }
    closeDemo=()=>{
        this.setState({
            showdemo:false
        })
    }

    showImage=(src)=>{
        console.log("src:",src)
        this.setState({
            showImage:true,
            imgsrc:src
        })
    }
    closeImage=()=>{
        this.setState({
            showImage:false
        })
    }

    render() {
        console.log("props:",this.props)
        const  theme  = this.props.pageContext.element
        const designimgs=theme.designimgs.map((img,index)=> <li key={index} className="theme-post  post-4724 post type-post status-publish format-standard hentry category-blog-themes category-corporate-themes category-featured-themes category-portfolio-themes category-responsive-themes category-themes">
            <figure className="theme-image">
                <div >
                    <img src={img} alt="img" onClick={()=>this.showImage(img)}/>
                </div>
            </figure>
        </li>)
        // const pluginimgs=theme.plugins.map((plugin,index)=> <li key={index}>
        //     <div><img className="border" src={plugin.img}  alt="plugin" /></div>
        //     <strong>{plugin.name}</strong>
        // </li>)
        const plugins=theme.plugins.map((plugin,index)=><li key={index} className="theme-plugin">
            <div className="plugin-item">
                <img className="plugin-img" alt="plugin" src={plugin.img}/>
                <div className="plugin-bg"></div>
            </div>

            <MyNavLink to={plugin.path}>
                {plugin.name}
            </MyNavLink>
        </li>)
        return (
          <Layout>
            <div>
                    <div>
                        <div id="page-single-theme" className="pagewidth clearfix fixed-sidebar">
                            <div id="content">
                                <article className="post theme-post">
                                    <figure className="theme-image"><span className="theme-frame"><span className="dot"></span><span
                                        className="dot"></span><span className="dot"></span></span>
                                        <a href={theme.demosrc} target="_blank">
                                            <img src={theme.poster} alt="Simple" />
                                        </a>
                                    </figure>
                                    <div className="who-use-list">

                                    </div>
                                    <h1 className="post-title">{theme.name}
                                        {theme.demosrc&&<a href="#" onClick={()=>this.showDemo(theme.demosrc)} className="tag-button demo">demo</a>}
                                    </h1>
                                    <p>{theme.desc}
                                    </p>

                                    <h3>响应式设计</h3>
                                    <p>主题设计流畅且响应迅速，可以在所有设备和分辨率上始终显示。
                                    </p>
                                    <p><img src={theme.responsiveImg}/></p>


                                    <h3>模板展示</h3>
                                    <ol className="grid3 theme-list clearfix">
                                        {designimgs}
                                    </ol>

                                    <div style={{display:theme.plugins.length>0?"block":"none"}}>
                                        <h3>使用的插件</h3>
                                        <ol className="grid3 clearfix">
                                            {/*{pluginimgs}*/}
                                            {plugins}
                                        </ol>
                                    </div>


                                </article>
                            </div>
                            <SingleThemeAside content={theme.features}/>
                        </div>
                    </div>
                {
                    this.state.showImage&&
                        <div>
                            <div className="mfp-bg mfp-ready"></div>
                            <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex="-1">
                                <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                                    <div className="mfp-content">
                                        <div style={{height:"100%"}}>
                                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={this.closeImage}>×</button>
                                            <div style={{height:"100%",overflow:"scroll"}}><img src={this.state.imgsrc}/></div>
                                        </div>
                                    </div>
                                    <div className="mfp-preloader">Loading...</div>
                                </div>
                            </div>
                        </div>
                }
            </div>

              {
                  this.state.showdemo&&
                  <div>
                      <DemoWindow src={theme.demosrc}/>
                  </div>
              }
          </Layout>

    )
    }
    }