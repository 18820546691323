import React from "react";
import './demo_window.css'
import PubSub from "pubsub-js";
import PropTypes from "prop-types";

export default class DemoWindow extends React.Component{
    static  propTypes= {
        src: PropTypes.string.isRequired,
    }
    closeDemo=()=>{
        PubSub.publish('closedemo')
    }
    render() {
        return(
            <div>
                <div className="mfp-bg mfp-ready"></div>
                <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabIndex="-1"
                     style={{top:'0',position:'absolute',height:'100%'}}>
                    <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                        <div className="mfp-content">
                            <div className="mfp-iframe-scaler">
                                <button title="Close (Esc)" type="button" className="mfp-close" onClick={this.closeDemo}>×</button>
                                <iframe id="iframe" className="mfp-iframe" src={this.props.src}
                                        frameBorder="0" allowFullScreen=""></iframe>

                            </div>
                        </div>
                        <div className="mfp-preloader">Loading...</div>
                    </div>
                </div>
            </div>
        )
    }
}