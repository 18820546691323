import React from "react";
import PropTypes from "prop-types"

export default class SingleThemeAside extends React.Component{
    static  propTypes= {
        content: PropTypes.array.isRequired,
    }
    render() {
        const content=this.props.content
        return(
            <aside id="sidebar">
                <div className="sidebar-inner" >

                    <div className="box">
                        <div  className="banner purple">
                            <h3>{content[0]}</h3>
                        </div>
                    </div>
                    <div className="box">
                        <div  className="banner green">
                            <h3>{content[1]}</h3>
                        </div>
                    </div>

                    <div className="box">
                        <div  className="banner blue">
                            <h3>{content[2]}</h3>
                        </div>
                    </div>


                </div>
                <div className="sticky-placeholder" style={{width: '310px ',height: '504px'}}></div>

            </aside>
        )
    }
}